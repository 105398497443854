@import "./variables.scss";

.selling-points-wrapper {
  padding: 0 0.75rem;
  margin-bottom: 2rem;

  .selling-points {
    .selling-point {
      display: grid;
      grid-template-columns: 1.75rem auto;
      grid-column-gap: 1.25rem;
      margin-bottom: 1.5rem;

      .icon-background {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 1.6875rem;
        height: 1.6875rem;
        border-radius: 50%;
        background-color: $color-purple;

        .icon {
          max-width: 0.875rem;
          max-height: 0.875rem;
        }
      }

      .text-wrapper {
        h3 {
          margin: 0 0 0.375rem 0;
        }

        .description {
          font-size: $font-size-small;
        }
      }
    }
  }
}
