$font-base: 16px;

$font-size-xxx-small: 0.625rem;
$font-size-xx-small:  0.7rem;
$font-size-x-small:   0.8rem;
$font-size-small:     0.9rem;
$font-size-medium:    1.0rem;
$font-size-large:     1.125rem;
$font-size-x-large:   1.25rem;

$font-size-button: $font-size-medium;

$font-weight-ultra-light:   200;
$font-weight-light:         300;
$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-demi-bold:     600;
$font-weight-bold:          700;
$font-weight-extra-bold:    900;

$breakpoint-viewport-4:     480px;
$breakpoint-viewport-7:     768px;
$breakpoint-viewport-9:     992px;
$breakpoint-viewport-12:    1200px;

$color-purple:            #990AE3;
$color-purple-bright:     #bb60eb;
$color-purple-xx-bright:  #faf2fe;
$color-gray-bright:       #f9f9f9;
$color-gray-bright-2:     #eaeaea;
$color-white:             #ffffff;

$color-text-primary:      #0D0D0D;
$color-text-secondary:    #838383;
$color-text-footer:       #858585;
$color-text-white:        #FFFFFF;
$color-text-gray-dark:    #454545;
$color-text-disabled:     #959595;
$color-text-placeholder:  #b9c4c9;

$color-hr-gray-bright:    #d2d2d2;
$color-border-gray-light: #eaeaea;
$color-form-border:       #dedede;

$color-button-disabled:   #f2f2f2;
$color-button-enabled:    #00CC66;
$color-button-primary: $color-text-primary;
$color-button-primary-background: $color-purple;

$color-checkbox-unchecked:#ffffff;
$color-checkbox-border:   #959595;
$color-checkbox-checked:  #7ED321;

$color-form-details:      #838383;

$width-max-page: 30rem;
$page-side-padding: 1.5rem;
