@import "./variables";
@import "./media-queries";

#title-header {
  padding: 0 2.75rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: 3.75rem;
  line-height: 3.75rem;
  max-width: $width-max-page;

  h2 {
    text-align: center;
    text-transform: uppercase;
    margin: 0;
  }

  &.purple {
    background: $color-purple;

    h2 {
      color: $color-text-white;
    }
  }
}
